import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Button from "../buttons/button"

const Outer = styled.div`
  background: rgb(38, 38, 38);
  background: linear-gradient(
	90deg,
	rgba(38, 38, 38, 1) 0%,
	rgba(0, 0, 0, 1) 100%
  );
  color: white;

  padding: 64px 0;

  .Testimonials_Left {
	display: none;
  }
  .Testimonials_Right {
	h2 {
	  font-size: 2.3rem;
	}
  }
  .Testimonials_Right_Text {
	max-width: 820px;
	margin: 0 auto;
  }

  @media (min-width: 1200px) {
	background: rgb(38, 38, 38);
	background: linear-gradient(
	  90deg,
	  rgba(38, 38, 38, 1) 0%,
	  rgba(0, 0, 0, 1) 100%
	);
	display: grid;
	grid-template-columns: 726px auto;
	grid-template-rows: 545px;
	align-items: center;

	padding: 0;

	.Testimonials_Left {
	  display: block;
	}

	.Testimonials_Right {
	  h2 {
		font-size: 3.35rem;
	  }
	}
  }
`

const HomeTestimonials = () => (
	<>
		<Outer>
			<div className="Testimonials_Left">
				<StaticImage
					src="../../images/home/selfie_fadeout.png"
					objectFit="cover"
					quality={95}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="testimonials placeholder"
				/>
			</div>
			<div className="Testimonials_Right">
				<div className="Testimonials_Right_Text">
					<h2>Testimonials</h2>
					<p>
						We protect a wide range of business and homes throughout the
						Okanangan, and the trust we've earned has provided us continious
						word-of-mouth referrals over the years. We're happy to do our part
						to increase fire and safety protection in our community. Here is a
						few of some of our satisfied customers who swear by our services:
					</p>
					<Link
						to="/contact" title="Contact Relentless Fire and Safety">
						<Button cta="Contact Us"></Button>
					</Link>
				</div>
			</div>
		</Outer>
	</>
)

export default HomeTestimonials

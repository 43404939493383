import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Button from "../buttons/button"

const Outer = styled.div`
  max-width: var(--site-width);
  margin: 0 auto;
`

const TwoColumnGrid = styled.div`
  display: grid;
  row-gap: 32px;
  align-items: center;

  @media (min-width: 768px) {
	grid-template-columns: 1fr 1fr;
	column-gap: 32px;
`


const HomeIntro = () => (
	<>
		<Outer>
			<TwoColumnGrid>
				<div>
					<h2>Relentless Fire &amp; Safety</h2>
					<p>
						We provide made-to-order fire inspection and safety services throughout all industries, including residential, commercial, industrial manufacturing, retail spaces, historic buildings, and newly constructed sites. We have the solution for, whatever your fire and safety needs are!
					</p>
					<Link to="/contact" title="Contact Relentless Fire and Safety">
						<Button cta="Contact Us"></Button>
					</Link>
				</div>
				<div>
					<StaticImage
						src="../../images/home/shop_truck.jpg"
						layout="fullWidth"
						aspectRatio={16 / 9}
						quality={100}
						formats={["AUTO", "WEBP", "AVIF"]}
						alt="fire and safety image a"
					/>
				</div>
			</TwoColumnGrid>
		</Outer>
	</>
)

export default HomeIntro

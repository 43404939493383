import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"

import ContentWrapper from "../components/wrappers/contentWrapper"
import HomeHero from "../components/heros/homeHero"
import HomeIntro from "../components/home/intro"
import HomeRestEasy from "../components/home/restEasy"
import HomeServices from "../components/home/services"
// import HomeTopShelf from "../components/home/topShelfServices"
import HomeTestimonial from "../components/home/testimonials"
import ReviewsComponent from "../components/home/reviews"

const SiteWidthWrapper = styled.div`
  padding: ${props => props.padding || "64px 0"};
  background: ${props => props.background};
`

const StyledHR = styled.hr`
  width: 65%;
  height: 4px;
  color: black;
  background: black;
  margin-top: 32px;
`

const ThreeColumnGrid = styled.div`
  display: grid;
  row-gap: 64px;
  padding: ${props => props.padding || "0"};

  h2 {
    text-align: center;
  }

  .ImgContainer {
    width: 85px;
    height: 85px;
    margin: 0 auto 32px auto;
  }

  // partners section start
  .Partners_Top_Padding {
    padding-top: 62px;
  }
  .ImgContainers_Partner {
    width: 170px;
    height: 170px;
    margin: 0 auto 32px auto;
  }
  .Partners_TextTitle {
    text-align: center;
  }
  // partners section end

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    column-gap: 32px;
  }
`

const IndexPage = () => (
  <Layout>
    <Seo
      title="Relentless Fire and Safety"
      description="made-to-order fire inspection and safety services throughout all industries, including residential, commercial, industrial manufacturing, retail spaces, historic buildings, and newly constructed sites."
    />
    {/* introduction start */}
    <HomeHero />
    {/* introduction End */}

    {/* about */}
    <SiteWidthWrapper padding="64px 0 0 0">
      <HomeIntro />
    </SiteWidthWrapper>
    {/* about end */}

    <div className="FakeSpace" />

    {/* Okanagan rest easy */}
    <SiteWidthWrapper
      padding="64px 0 0 0"
      background="linear-gradient(
        90deg,
        #CC4423  0%,
        #842917 100%)"
    >
      <HomeRestEasy />
    </SiteWidthWrapper>
    {/* Okanagan rest easy end */}

    {/* services start */}

    {/* services intro */}
    <SiteWidthWrapper background="hsl(210, 4%, 91%)">
      <ContentWrapper>
        <h2 className="CenterText">Services</h2>
        <p>
          Relentless Fire and Safety provides full spectrum protection with our
          premier standards of service. We offer the people and businesses
          throughout the Okanagan total fire safety through our comprehensive,
          hi-tech systems. Browse below through the summaries to see just some
          of what we offer to our clients.
        </p>
        <p>
          We go above and beyond to ensure that we personalize our work each
          customer.
        </p>
        <StyledHR />
      </ContentWrapper>
    </SiteWidthWrapper>
    {/* services intro end */}

    <SiteWidthWrapper padding="16px 0 64px 0" background="hsl(210, 4%, 91%)">
      <HomeServices />
    </SiteWidthWrapper>
    {/* services end */}

    {/* top shelf services */}
    {/* <SiteWidthWrapper>
			<HomeTopShelf />
		</SiteWidthWrapper> */}
    {/* top shelf services */}

    {/* reviews component */}
    <ReviewsComponent />
    {/* reviews component */}

    {/* testimonials */}
    <HomeTestimonial />
    {/* testimonials end */}

    {/* partners */}
    <SiteWidthWrapper>
      <h2 className="CenterText">Partners</h2>
      <ContentWrapper>
        <p>
          It is a key part of Relentless Fire and Safety belief system to be a
          trusted partner in both our industry and the broader community by
          taking an active role in advocating for improved safety standards
          among the various organizations we cooperate with. It is our privilege
          to collaborate with so many outstanding corporate citizens. To name a
          few:
        </p>
        <ThreeColumnGrid padding="64px 0 0 0">
          <div className="Partners_One">
            <div className="ImgContainers_Partner">
              <StaticImage
                src="../images/home/chamber_partner.png"
                layout="fullWidth"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Penticton chamber of commerce"
              />
            </div>
            <p className="Partners_TextTitle">The Chamber of Penticton</p>
          </div>
          <div className="Partners_Two">
            <div className="ImgContainers_Partner">
              <StaticImage
                src="../images/home/nfpa_partner.png"
                layout="fullWidth"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="national fire protection association"
              />
            </div>
            <p className="Partners_TextTitle">
              National Fire Protection Association
            </p>
          </div>
          <div className="Partners_Three">
            <div className="ImgContainers_Partner">
              <StaticImage
                src="../images/home/cfaa_partner.png"
                layout="fullWidth"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="canadian fire alarm association"
              />
            </div>
            <p className="Partners_TextTitle">
              Canadian Fire Alarm Association
            </p>
          </div>
        </ThreeColumnGrid>
      </ContentWrapper>
    </SiteWidthWrapper>
    {/* partners end */}
  </Layout>
)

export default IndexPage

import * as React from "react"
import styled from "@emotion/styled"
import { Script } from "gatsby"

const Outer = styled.div`
  padding: 64px 0 0 0;

  .iframe {
    width: 100%;
    border: none;
  }

  ${
    "" /* @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 726px auto;
    grid-template-rows: 545px;
    align-items: center;

    padding: 0;
  } */
  }
`

const ReviewsComponent = () => (
  <Outer>
    <Script
      type="text/javascript"
      src="https://api.leadconnectorhq.com/js/reviews_widget.js"
    />
    <iframe
      className={`iframe`}
      title={"review widget"}
      id="msgsndr_reviews"
      src="https://services.leadconnectorhq.com/appengine/reviews/get_widget/BS2x39rB5DVieobZkcRN"
    />
  </Outer>
)

export default ReviewsComponent

import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import Button from "../buttons/button.js"

const HeroText = styled.div`
display: grid;

justify-content: center;
align-items: center;

margin-top: 32px;

.image {
	margin: 0 auto 32px auto;
}

.text {
	font-weight: bold;
	h1, p {
	text-align: center;
	color: white;
}

h1 {
	font-weight: normal;
	font-size: 2rem;
	text-align: center;
	@media(min-width: 798px) {line-height: .5;}
}

.keepingYouSafe {
	font-size: 2.5rem;
	@media(min-width: 768px) {
		font-size: 5rem;
	}
}

.col {
	margin-bottom: 50px;
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
}

@media(min-width: 768px) {
	h1 {margin-top: 0;}
}

`

const HomeHero = () => (
	<div style={{ display: "grid" }}>
		{/* You can use a GatsbyImage component if the image is dynamic */}
		<StaticImage
			style={{
				gridArea: "1/1",
				// You can set a maximum height for the image, if you wish.
				maxHeight: 590,
			}}
			layout="fullWidth"
			// You can optionally force an aspect ratio for the generated image
			aspectRatio={16 / 9}
			objectFit="center"
			// This is a presentational image, so the alt should be an empty string
			alt=""
			src={
				"../../images/heros/home_hero_image.jpg"
			}
			formats={["auto", "webp", "avif"]}
			loading="eager"
			placeholder="tracedSVG"
		/>

		{/* add an opacity with this row if needed */}
		{/* <div
			style={{
				// By using the same grid area for both, they are stacked on top of each other
				gridArea: "1/1",
				position: "relative",
				// This centers the other elements inside the hero component
				placeItems: "center",
				display: "grid",
				background: "rgba(0, 0, 0, 0.4)"
			}}
		></div> */}
		{/* add an opacity END */}

		<div
			style={{
				// By using the same grid area for both, they are stacked on top of each other
				gridArea: "1/1",
				position: "relative",
				// This centers the other elements inside the hero component
				placeItems: "center",
				display: "grid",
			}}
		>
			{/* Any content here will be centered in the component */}
			<HeroText>
				<div className="image">
					<StaticImage
						src="../../images/home/r_logo_icon.png"
						width={84}
						height={62}
						quality={95}
						formats={["AUTO", "WEBP", "AVIF"]}
						alt="fire and safety placeholder image"
						placeholder="tracedSVG"
					/>
				</div>
				<div className="text">
					<h1>
						Fire Protection, Installations &amp; Inspections
					</h1>
					<p className="keepingYouSafe">
						Keeping You Safe
					</p>
					<p>
						in the Okanagan
					</p>
					<div className="col buttons">
						<a href="tel:12504629111" title="call 250-462-9111">
							<Button cta="Call Now" />
						</a>
					</div>
				</div>
			</HeroText>
		</div>
	</div>
)

export default HomeHero
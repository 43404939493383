import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import OkanaganBackgroundImage from "../../images/home/free_quote_cell.png"

const Outer = styled.div`
  max-width: var(--site-width);
  margin: 0 auto;
`

const TwoColumnGrid = styled.div`
   display: grid;
  row-gap: 32px;
  align-items: center;

  color: white;
  font-weight: bold;

  .OkanaganLeft {
	max-height: 300px;
	text-align: center;

	.TextA {
	  font-size: 2.5rem;
	}

	.TextB {
	  font-size: 3rem;
	 }

	 .TextBLink {
		 color: white;
		 font-size: 3rem;
		 margin-left: 0;
	 }
  }

  .OkanaganRight {
	background: url(${OkanaganBackgroundImage}) bottom;
	background-repeat: no-repeat;
	height: 200px;
	background-size: contain;
  }

  @media (min-width: 768px) {
	grid-template-columns: 1fr 1fr;
	column-gap: 32px;

	// .OkanaganLeft  {
	// 	text-align: left;
	// }
	.OkanaganRight {
	  height: 300px;
	}
  }
`


const HomeRestEasy = () => (
	<>
		<Outer>
			<TwoColumnGrid>
				<div className="OkanaganLeft">
					<p className="TextA">For A Free On-Site Inspection</p>
					<p className="TextB"><a className="TextBLink" href="tel:+12504629111" title="onsite fire and safety inspection call now">250.462.9111</a></p>
				</div>
				<div className="OkanaganRight"></div>
			</TwoColumnGrid>
		</Outer>
	</>
)

export default HomeRestEasy
